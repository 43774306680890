import React, {useCallback, useContext, useEffect} from 'react';
import {Table} from "react-bootstrap";
import CustomButton from "../../../elements/CustomButton/CustomButton";
import {displayAmount} from "../../../utils/modelUtils/almazen/unitsUtility";
import {productBrandName} from "../../../utils/modelUtils/almazen/productBrandUtils";
import {
    detailSerializationGroups, isAnyMovementCompleted, isStockRequestCancellable, isStockRequestReceivable,
    splitMovementsByDirectionFromStockRequest, stockRequestStatus, stockRequestStatusTrans
} from "../../../utils/modelUtils/almazen/stockRequestUtils";
import {SecurityContext} from "../../../utils/SecurityManager";
import {ApiContext} from "../../../api/Api";
import {Link} from "react-router-dom";
import useTideEntity from "../../../hooks/useTideEntity";
import ApprovalsTable from "../../../components/Approvals/ApprovalsTable";
import CommentsFeed from "../../../components/CommentsFeed/CommentsFeed";
import ProductRequisitionFormModal from "./ProductRequisitionFormModal";
import useBoolean from "../../../hooks/useBoolean";
import {employeeFullName} from "../../../utils/modelUtils/alma/employeeUtils";
import moment from "moment";

const ProductRequisitionDetail = ({match, history} )=> {

    const api = useContext(ApiContext);

    useEffect(() => {
        api.clearProperty("ProductRequisitionDetail");
    }, [api]);

    const [stockRequest, loading, notFound, {reload}] = useTideEntity(
        'stockRequests',
        match.params.id,
        {customProp: 'ProductRequisitionDetail', requestFilters: {sGroups: detailSerializationGroups}});

    const { inMovements, outMovements } = splitMovementsByDirectionFromStockRequest(stockRequest);
    const security = useContext(SecurityContext);
    const [editing, startEditing, stopEditing] = useBoolean();
    const [cloning, startCloning, stopCloning] = useBoolean();
    const hideFormModal = useCallback(() => {
        stopCloning();
        stopEditing();
    }, [stopEditing, stopCloning]);

    const handleCancel = () => {
        api.stockRequests.edit({id: stockRequest.id, stockRequest: {status: stockRequestStatus.CANCELED}})
            .then(reload);
    };

    const handleEdition = useCallback((newStockRequest) => {
        stopEditing();
        stopCloning();
        if (newStockRequest)
            history.push('/almazen/product-requisition/' + newStockRequest.id);
    }, [stopEditing, stopCloning, history]);

    const showBothEditButtons =
        security.almazen.canEditStockRequest(stockRequest) &&
        stockRequest.status !== stockRequestStatus.CANCELED &&
        !isAnyMovementCompleted(stockRequest) &&
        security.almazen.canRequestStock();

    const determineTextColor = (expectedQuantity) => {
        if(inMovements.length>0){
            const totalInQuantity = inMovements.reduce((total, move) => total + Math.abs(move.quantity), 0);
            return Math.abs(expectedQuantity) > totalInQuantity ? 'red' : 'black';
        }else{
            return 'black'
        }
    };
    return (
        <div className='ProductRequisitionDetail container-fluid main-container'>
            <h2>
                {stockRequest && <span>
                    Requisición de almacén&nbsp;{stockRequest.folio}<br/>
                    <span
                        className="small">{stockRequest.createdBy?
                        "Solicitado por "+employeeFullName(stockRequest.createdBy.employee):
                        "Generada por el sistema"
                        }
                    </span>

                </span>}
                {notFound && 'Requisición no encontrada'}
                {loading && 'Cargando...'}
            </h2>

            {stockRequest &&
            <div>
                <div className='from-cont text-center'>
                    <h3>{stockRequest.fromWarehouse.name}&nbsp;
                        <i className="fa fa-arrow-right" aria-hidden="true"/>&nbsp;
                        {stockRequest.toPlace.name}</h3>
                </div>
                <div className='requisition-actions'>

                    <span className='pull-right status-title'>{stockRequestStatusTrans[stockRequest.status]}</span>
                    {security.almazen.canReceiveStockRequests(stockRequest) &&
                    isStockRequestReceivable(stockRequest) &&
                    <Link to={'/almazen/product-requisition/receive/' + stockRequest.id}>
                        <CustomButton bsStyle='success' className='receive-btn'>Recibir requisición</CustomButton>
                    </Link>}

                    {security.almazen.canEditStockRequest(stockRequest) &&
                    stockRequest.status !== stockRequestStatus.CANCELED &&
                    !isAnyMovementCompleted(stockRequest) &&
                    <CustomButton bsStyle='primary' onClick={startEditing}
                                  className={showBothEditButtons ? 'but-left' : ''}>
                        Editar requisición
                    </CustomButton>}

                    {security.almazen.canRequestStock() &&
                    <CustomButton bsStyle='primary' onClick={startCloning}
                                  className={showBothEditButtons ? 'but-right' : ''}>
                        Clonar requisición
                    </CustomButton>}

                    {security.almazen.canReceiveStockRequests(stockRequest) && isStockRequestCancellable(stockRequest) &&
                    <CustomButton bsStyle='danger' className='cancel-btn' onClick={handleCancel}>Cancelar
                        requisición</CustomButton>}
                    <div className='clearfix'/>

                </div>
                <hr/>
                <h4>Movimientos de salida:</h4>
                {!!outMovements?.length ?
                    <Table className=' vertical-responsive-table'>
                        <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Cantidad</th>
                            <th>Estado</th>
                            <th>Completado</th>
                        </tr>
                        </thead>
                        <tbody>
                        {outMovements.map((move) => (
                            <tr key={move.id}>
                                <td data-label='Prod.'> {productBrandName(move.productBrand)}</td>
                                <td data-label='Cant.'  style={{ color: determineTextColor(move.expectedQuantity)}}>
				{displayAmount(Math.abs(move.expectedQuantity), move.productBrand.product)} </td>
                                <td data-label='Estado.'> {stockRequestStatusTrans[move.status]} </td>
                                <td data-label='Completado'>{move.completedDate?moment(move.completedDate).format('DD/MM/YYYY HH:mm'):'-'}</td>
                            </tr>
                        ))}

                        </tbody>
                    </Table>
                    :
                    'Sin movimientos registrados.'
                }
                <hr/>
                <h4>Movimientos de entrada:</h4>
                {!!inMovements?.length ?
                    <Table className=' vertical-responsive-table'>
                        <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Cantidad</th>
                            <th>Estado</th>
                            <th>Completado</th>
                        </tr>
                        </thead>
                        <tbody>
                        {inMovements.map((move) => (
                            <tr key={move.id}>
                                <td data-label='Prod.'> {productBrandName(move.productBrand)}</td>
                                <td data-label='Cant.'>
				{displayAmount(Math.abs(move.quantity), move.productBrand.product)} </td>
                                <td data-label='Estado.'> {stockRequestStatusTrans[move.status]} </td>
                                <td data-label='Completado'>{move.completedDate?moment(move.completedDate).format('DD/MM/YYYY HH:mm'):'-'}</td>
                            </tr>
                        ))}

                        </tbody>
                    </Table>
                    :
                    'Sin movimientos registrados.'
                }

                {stockRequest.status !== stockRequestStatus.CANCELED ?
                    <ApprovalsTable
                        approvalEntityName='stockRequestApprovals'
                        approvals={stockRequest.stockRequestApprovals}
                        onChange={reload}
                    />
                    :
                    <p className='canceled-alert'>Requisición cancelada</p>
                }

                <CommentsFeed
                    title="Comentarios y archivos"
                    cassetteId={stockRequest.cassette ? stockRequest.cassette.id : null}
                />

            </div>}

            {(editing || cloning) &&
            <ProductRequisitionFormModal
                stockRequest={stockRequest}
                onHide={hideFormModal}
                onEdit={handleEdition}
                cloning={!!cloning}
            />}

        </div>
    );
};

export default ProductRequisitionDetail;
